import { Stack } from '@mui/material';
import { Outlet } from '@remix-run/react';
import { AnonymousLayout } from '~/components/AnonymousLayout';

export { ErrorBoundary } from '~/components/AnonymousLayout';

export default function () {
  return (
    <AnonymousLayout>
      <Stack sx={{ maxWidth: { xs: '400px' } }} flex={1}>
        <Outlet />
      </Stack>
    </AnonymousLayout>
  );
}
